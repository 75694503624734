var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.displayEdit)?_c('div',[_c('v-sheet',{staticClass:"mt-3 mb-3 overflow-hidden",staticStyle:{"border-radius":"8px"},style:(("border : 1px solid " + _vm.wsDARKLIGHT + " !important")),attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-sheet',{key:color,class:[{roundedTopLeft : index === 0} , {roundedTopRight : index === 5}],attrs:{"width":"100%","height":"100","color":_vm.getColor(color)}})}),1),_c('v-divider',{style:(("border-color : " + _vm.wsDARKLIGHT + "; height : 1px"))}),_c('v-btn',{staticClass:"noCaps",attrs:{"block":"","text":"","color":_vm.wsACCENT},on:{"click":_vm.openEditPalette}},[_vm._v(" "+_vm._s(_vm.$t('EditPalette')))])],1)],1):_c('div',[_c('v-sheet',{staticClass:"mt-3 mb-3",staticStyle:{"border-radius":"8px"},attrs:{"color":"transparent"}},[_c('div',{staticClass:"d-flex"},_vm._l((_vm.AVALON_COLORS_LIST),function(color,index){return _c('v-hover',{key:color,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pointer",class:[
              {roundedTopLeft : index === 0} ,
              {roundedBottomLeft : index === 0} ,
              {roundedTopRight : index === 5},
              {roundedBottomRight : index === 5}
          ],style:(hover || (_vm.selectedColorName) === color ? ("box-shadow : 0 0 0 2px " + _vm.wsDARKLIGHT + "; margin-right: 2px") : null),attrs:{"color":_vm.getColor( color),"width":"100%","height":"32"},on:{"click":function($event){_vm.selectedColorName = color}}},[_c('span',{staticStyle:{"display":"none"}},[_vm._v(_vm._s(color))])])]}}],null,true)})}),1)]),_c('ws-text-field',{staticClass:"mt-4",attrs:{"background-color":"white"},on:{"change":_vm.editPaletteColor},model:{value:(_vm.paletteBuffer[_vm.selectedColorName]),callback:function ($$v) {_vm.$set(_vm.paletteBuffer, _vm.selectedColorName, $$v)},expression:"paletteBuffer[selectedColorName]"}}),_c('v-color-picker',{staticClass:"mt-3",staticStyle:{"background-color":"transparent"},attrs:{"value":_vm.getColor(_vm.paletteBuffer[_vm.selectedColorName]),"hide-inputs":"","hide-mode-switch":""},on:{"input":_vm.changeSliderColor}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }