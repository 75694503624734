<template>
<div class="px-5">

  <block-settings-navigation
      v-model="selectedContentCategory"
      :items="sliderContentSelect"
      :return-action="handleReturnAction"
      portal
      class="mt-5"
  >

    <template #item.style>
      <avalon-palette-picker
          :palette="EMAIL_CONFIG.palette"
          :color-function="EMAIL_COLOR"
          @edit-palette="editPaletteColor"
          @display-edit="displayEditPalette = $event"
          :hide-palette-trigger="hidePaletteTrigger"
      />

      <div v-if="!displayEditPalette">
        <div v-for="(group,i) in colorsParamsArray" :key="i + 'group'">
          <h4 class="wsDARKER">{{  group.name  }}</h4>
          <avalon-color-picker
              @input="editNewsletterConfig"
              v-for="(param,j) in group.items" :key="j + 'group'"
              :label="param.text"
              v-model="$store.state.finemailer.newsletterConfig.colors[param.value]"
              :color-function="EMAIL_COLOR"
              font-regular
              :class="[{'mb-10' : i === (colorsParamsArray.length -1) && j=== (group.items.length -1)  }]"
          />
          <v-divider v-if="i < colorsParamsArray.length -1" class="my-3 mb-5" :style="`border-color : ${wsBACKGROUND}`" />
        </div>
      </div>


    </template>

    <template #item.fonts>
      <div v-if="!selectedFontParam">
        <!-- Current fonts -->
        <v-sheet
            :style="`border : 1px solid ${wsDARKLIGHT} !important`"
            class="mt-3 mb-3 "
            style="border-radius: 8px"
        >

          <div class="pa-4 ">
            <div class="cut-text">
              <h1 :style="`color : ${wsDARKER};font-family : ${ EMAIL_CONFIG.font_family || 'Helvetica'}`">
                {{ EMAIL_CONFIG.font_family || 'Arial' }}
              </h1>
            </div>

            <h4 :style="`font-family : ${ EMAIL_CONFIG.font_family || 'Helvetica'}`"
                style="font-size: 14px" class="font-weight-regular wsDARKER mt-1">
              {{ $t('mailer.editor.fonts.paragraph_placeholder')}}
            </h4>
          </div>

          <v-divider  :style="`border-color : ${wsDARKLIGHT}; height : 1px`" />
          <v-btn
              @click="selectedFontParam = 'font_family'"
              block text
              :color="wsACCENT"
              class="noCaps py-5"
          >
            {{ $t('ChooseFonts') }}
          </v-btn>
        </v-sheet>

        <h4 class="wsDARKER">
          {{ $t('FontsSettings') }}
        </h4>
      </div>

      <block-settings-navigation
          v-model="selectedFontParam"
          :items="fontsParamsSelect"
          hide-return
          class="mt-5"
      >
        <template #item.font_family>

          <h5 class="font-weight-regular wsDARKER mb-3">{{ $t('mailer.editor.fonts.warning') }} </h5>

          <v-hover
              #default="{hover}"
              v-for="(fontPair, index) in EMAIL_FONT_PAIRS_SELECT"  :key="'palette_' + index "
          >
            <div class="d-flex align-center">
              <v-sheet
                  width="100%"
                  @click="changeFonts(fontPair , index)"
                  class="mt-1 mb-1 pointer py-3 px-2"
                  style="border-radius: 6px"
                  color="transparent"
                  :style="`box-shadow : 0 0 0 ${hover || index === selectedFontIndex ? 2 : 1}px ${wsDARKLIGHT} !important`"
              >
                <div class="d-flex align-center">
                  <ws-check-box
                      @prevent="changeFonts(fontPair , index)"
                      :value="selectedFontIndex === index"
                      :color="wsACCENT"
                      :selected-color="wsACCENT"
                      :small="false"
                      class="mr-1"
                      prevent-select
                      radio
                  />
                  <div>
                    <h2 :style="`font-family : ${fontPair.heading}`">{{ fontPair.heading }}</h2>
                    <h5 style="font-size : 14px" class="font-weight-regular shorten-text-two" :style="`font-family : ${fontPair.paragraph}`">
                      {{ $t('mailer.editor.fonts.paragraph_placeholder') }}
                    </h5>
                  </div>
                </div>
              </v-sheet>
            </div>
          </v-hover>


        </template>
        <template #item.headings>

          <a-parameter-select
              class="pt-5"
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_family_heading"
              :items="EMAIL_FONTS_SELECT"
              label="Font"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />
          <a-parameter-select
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_weight_heading"
              :items="EMAIL_FONT_WEIGHT_SELECT"
              label="FontWeight"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

          <a-parameter-slider
              @change="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_line_height_heading"
              default="0"
              :label="$t('avalon.config.fonts.line_height')"
              :track-color="wsBACKGROUND"
              :font-color="wsDARKER"
              :value-color="wsACCENT"
              class="pb-6"
              min="100"
              max="200"
              light
          />
          <a-parameter-select
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_case_heading"
              :items="EMAIL_FONT_CASE_SELECT"
              label="FontCase"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 " :style="`border-color : ${wsDARKLIGHT}; `" />

          <div v-for="(screenSize , i) in ['','_sm']" :key="i">
            <h4 class="pt-7 pb-2" :style="`color : ${wsDARKER}`">
              {{ $t(`Sizes` + screenSize) }}
            </h4>
            <a-parameter-slider
                v-for="size in ['h1','h2','h3','h4','h5']" :key="i + size"
                @change="editNewsletterConfig"
                v-model="$store.state.finemailer.newsletterConfig['font_size_' + size + screenSize]"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                :label="`font.${size}`"
                min="10"
                max="50"
                light
                class="mb-3"
            />
          </div>

        </template>
        <template #item.paragraphs>

          <a-parameter-select
              class="pt-5"
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_family_paragraph"
              :items="EMAIL_FONTS_SELECT"
              label="Font"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 mb-6" :style="`border-color : ${wsDARKLIGHT}; `" />
          <a-parameter-select
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_weight_paragraph"
              :items="EMAIL_FONT_WEIGHT_SELECT"
              label="FontWeight"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 mb-3" :style="`border-color : ${wsDARKLIGHT}; `" />

          <a-parameter-slider
              @change="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_line_height_paragraph"
              default="0"
              :label="$t('avalon.config.fonts.line_height')"
              :track-color="wsBACKGROUND"
              :font-color="wsDARKER"
              :value-color="wsACCENT"
              class="pb-6"
              min="100"
              max="200"
              light
          />
          <a-parameter-select
              @input="editNewsletterConfig"
              v-model="$store.state.finemailer.newsletterConfig.font_case_paragraph"
              :items="EMAIL_FONT_CASE_SELECT"
              label="FontCase"
              :label-color="wsDARKER"
              :value-color="wsACCENT"
              null-text="Default"
              light
          />
          <v-divider class="mt-3 " :style="`border-color : ${wsDARKLIGHT}; `" />

          <div v-for="(screenSize , i) in ['','_sm']" :key="i">
            <h4 class="pt-7 pb-2" :style="`color : ${wsDARKER}`">
              {{ $t(`Sizes` + screenSize) }}
            </h4>
            <a-parameter-slider
                v-for="size in ['p','h6']" :key="i + size"
                @change="editNewsletterConfig"
                v-model="$store.state.finemailer.newsletterConfig['font_size_' + size + screenSize]"
                style="width: 100%"
                :track-color="wsDARKLIGHT"
                :value-color="wsACCENT"
                :label="`font.${size}`"
                min="10"
                max="50"
                light
                class="mb-3"
            />
          </div>

        </template>
      </block-settings-navigation>

    </template>

    <template #item.email>
      <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Padding') }}</h4>
      <ws-button-group
          v-model="emailBodyPaddingType"
          @input="handleEmailBodyPaddingTypeChange"
          :items="PADDING_SELECT"
          class="mb-2 mt-2"
          dense
      />
      <div v-if="emailBodyPaddingType === 'custom' ">
        <a-parameter-slider
            @change="editNewsletterConfig"
            v-model="$store.state.finemailer.newsletterConfig.body_padding_top"
            default="0"
            :label="$t('PaddingTop')"
            :track-color="wsBACKGROUND"
            :font-color="wsDARKER"
            :value-color="wsACCENT"
            class="pb-6"
            min="0"
            max="50"
            light
        />
        <a-parameter-slider
            @change="editNewsletterConfig"
            v-model="$store.state.finemailer.newsletterConfig.body_padding_bottom"
            default="0"
            :label="$t('PaddingBottom')"
            :track-color="wsBACKGROUND"
            :font-color="wsDARKER"
            :value-color="wsACCENT"
            class=" pb-6"
            min="0"
            max="50"
            light
        />
        <a-parameter-slider
            @change="editNewsletterConfig"
            v-model="$store.state.finemailer.newsletterConfig.body_padding_left"
            default="0"
            :label="$t('PaddingLeft')"
            :track-color="wsBACKGROUND"
            :font-color="wsDARKER"
            :value-color="wsACCENT"
            class=" pb-6"
            min="0"
            max="50"
            light
        />
        <a-parameter-slider
            @change="editNewsletterConfig"
            v-model="$store.state.finemailer.newsletterConfig.body_padding_right"
            default="0"
            :label="$t('PaddingRight')"
            :track-color="wsBACKGROUND"
            :font-color="wsDARKER"
            :value-color="wsACCENT"
            class=" pb-6"
            min="0"
            max="50"
            light
        />
      </div>

      <h4 class="mt-4" style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Margin') }}</h4>
      <a-parameter-slider
          @change="editNewsletterConfig"
          v-model="$store.state.finemailer.newsletterConfig.body_margin_top"
          default="0"
          :label="$t('PaddingTop')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class="pb-6"
          min="0"
          max="50"
          light
      />
      <a-parameter-slider
          @change="editNewsletterConfig"
          v-model="$store.state.finemailer.newsletterConfig.body_margin_bottom"
          default="0"
          :label="$t('PaddingBottom')"
          :track-color="wsBACKGROUND"
          :font-color="wsDARKER"
          :value-color="wsACCENT"
          class=" pb-6"
          min="0"
          max="50"
          light
      />

      <ws-accordion :items="acordionColorItems">

        <template #item.color>
          <div class="px-4 py-2">
            <avalon-color-picker
                @input="editNewsletterConfig"
                v-for="(param,j) in colorsParamsArray[0].items" :key="j + 'email'"
                :label="param.text"
                v-model="$store.state.finemailer.newsletterConfig.colors[param.value]"
                :color-function="EMAIL_COLOR"
                font-regular
                :class="[{'mb-10' : i === (colorsParamsArray.length -1) && j=== (group.items.length -1)  }]"
            />
          </div>
        </template>
      </ws-accordion>
    </template>

    <template #item.logo>
      <image-param-picker
          class="pt-5"
          @input="editNewsletterConfig"
          v-model="$store.state.finemailer.newsletterConfig.logo"
          disable-portal
      />

      <!-- Horizontal Alignment -->
      <div class="mt-5">
        <h4 style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Align') }}</h4>
        <ws-button-group
            @input="editNewsletterConfig"
            v-model="$store.state.finemailer.newsletterConfig.logo_align"
            :items="ALIGNMENT_SELECT"
            class="mb-2 mt-2"
        />
      </div>

      <!-- Logo Height  -->
      <a-parameter-slider
          v-if="$store.state.finemailer.newsletterConfig.logo"
          @change="editNewsletterConfig"
          v-model="$store.state.finemailer.newsletterConfig.logo_height"
          default="50"
          :track-color="wsDARKLIGHT"
          :value-color="wsACCENT"
          :label="$t('LogoHeight')"
          class="mt-4 pb-10"
          unit="px"
          min="30"
          max="120"
          light
      />

    </template>

    <template #item.footer>
      <block-settings-navigation
          v-model="selectedFooterCategory"
          :items="footerContentSelect"
          hide-return
          class="mt-5"
      >
        <template #item.content>
          <h5 class="wsACCENT font-weight-regular">
            {{ $t('mailer.editor.settings.contacts_description') }}
          </h5>

<!--          <ws-text-field-->
<!--              @change="editBusinessEmailConfig($event , 'email')"-->
<!--              v-model="$store.state.finemailer.newsletterConfig.email"-->
<!--              class="mt-5"-->
<!--              :label="$t('Email')"-->
<!--              :placeholder="$t('example@example.com')"-->
<!--              avalon-style-->
<!--          />-->
          <ws-text-field
              @change="editBusinessEmailConfig($event , 'phone')"
              v-model="$store.state.finemailer.newsletterConfig.phone"
              :label="$t('Phone')"
              placeholder="+380"
              avalon-style
              class="mt-5"
              clearable
          />
          <ws-text-field
              @change="editBusinessEmailConfig($event , 'address')"
              v-model="$store.state.finemailer.newsletterConfig.address"
              :label="$t('Address')"
              :placeholder="$t('EnterAddress')"
              avalon-style
              rows="1"
              area
              class="mt-5"
              clearable
          />

          <ws-accordion
              :items="footerSocialsSelect"
              class="mt-6"
          >
            <template #item.socials>

              <!-- No Socials -->
              <div v-if="!EMAIL_CONFIG.socials || (EMAIL_CONFIG.socials && EMAIL_CONFIG.socials.length === 0)" class="d-flex align-center justify-center fill-height px-5 py-10">
                <div >
                  <div class="d-flex justify-center">
                    <v-icon size="40" :color="wsDARKLIGHT">mdi-assistant</v-icon>
                  </div>
                  <h4  class="text-center mt-3">
                    {{ $t('avalon.header.socials.no_content') }}
                  </h4>
                  <ws-button
                      @click="addSocial"
                      label="avalon.header.socials.add"
                      height="40"
                      block
                      class="mt-5"
                  />

                </div>

              </div>
              <!-- Socials List -->
              <div class="pa-5" v-else>
                <!-- Socials List -->
                <draggable
                    v-if="selectedSocialIndex === null"
                    v-model="$store.state.finemailer.newsletterConfig.socials"
                    :forceFallback="true"
                    :empty-insert-threshold="120"
                    v-bind="opt"
                    @start="drag = true"
                    @change="editNewsletterConfig"
                    handle=".topic-handle"
                    @end="drag = false"
                >

                  <transition-group type="transition" :name="!drag ? 'flip-list' : null">
                    <v-hover
                        #default="{hover}"
                        v-for="(item,index) in EMAIL_CONFIG.socials" :key="index"
                    >
                      <v-sheet
                          @click="selectedSocialIndex = index ;"
                          :style="`box-shadow : 0 0 0 ${hover ? 2 : 1}px ${hover ? wsACCENT : wsBACKGROUND}`"
                          class="pointer d-flex justify-space-between align-center wsRoundedLight  mt-2 pr-3"
                          height="48"
                      >
                        <div class="d-flex align-center shorten-text">
                          <v-sheet min-width="24" style="cursor: grab;"  class="topic-handle"  color="transparent">
                            <v-icon  :color="wsDARKLIGHT">mdi-drag-vertical</v-icon>
                          </v-sheet>

                          <h5 :style="!(item.name || item.link) ? `color : ${wsDARKLIGHT}` : ''" class="shorten-text" style="font-size: 14px; " >
                            {{ item.name || item.link || $t('avalon.header.socials.add_content') }}
                          </h5>
                        </div>
                        <div class="d-flex align-center">
                          <div v-if="hover" class="d-flex align-center">
                            <v-btn
                                @click.stop="duplicateSocial(item, index)"
                                small icon :color="wsDARKLIGHT">
                              <v-icon :size="20">mdi-content-copy</v-icon>
                            </v-btn>
                            <v-btn
                                @click.stop="deleteSocial(index)"
                                small icon :color="wsDARKLIGHT">
                              <v-icon>mdi-delete-outline</v-icon>
                            </v-btn>
                          </div>
                          <v-icon :color="wsACCENT">mdi-menu-right</v-icon>
                        </div>


                      </v-sheet>
                    </v-hover>
                  </transition-group>
                </draggable>
                <!-- Edit Item-->
                <div v-else>
                  <div
                      class="d-flex justify-space-between align-center mb-5">
                    <h5 @click="selectedSocialIndex = null"
                        :style="`color : ${wsACCENT}`"
                        class="pointer text-no-wrap"
                    >
                      <v-icon class="mr-2" :color="wsACCENT">mdi-arrow-left</v-icon>
                      {{ $t('GoBack') }}
                    </h5>
                    <h5 class="text-right shorten-text">{{
                        $store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].name ||
                        $store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].link
                      }}</h5>
                  </div>

                  <ws-text-field
                      @change="setSocialLink"
                      v-model="$store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].link"
                      :label="$t('Link')"
                      :placeholder="$t('avalon.header.socials.enter_link')"
                      class="mb-5"
                      width="100%"
                      avalon-style
                  />
                  <ft-select
                      @input="selectSocial"
                      :items="SOCIALS_SELECT"
                      close-on-click
                      nudgeLeft="0"
                  >
                    <ws-text-field
                        @change="editNewsletterConfig"
                        v-model="$store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].name"
                        :label="$t('SocialNetwork')"
                        :placeholder="$t('avalon.header.socials.choose_social')"
                        class="mb-5"
                        width="100%"
                        avalon-style
                    />
                  </ft-select>

                  <image-param-picker
                      @input="editNewsletterConfig"
                      v-model="$store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].img"
                      disable-portal
                  />

                  <a-parameter-slider
                      v-if="!MOBILE_VIEW"
                      @change="editNewsletterConfig"
                      v-model="$store.state.finemailer.newsletterConfig.socials[selectedSocialIndex].size"
                      default="24"
                      :track-color="wsDARKLIGHT"
                      :value-color="wsACCENT"
                      :label="$t('Size')"
                      class="mt-4 pb-10"
                      unit="px"
                      min="12"
                      max="80"
                  />

                </div>
                <ws-button
                    v-if="selectedSocialIndex === null"
                    @click="addSocial"
                    label="avalon.header.socials.add"
                    height="40"
                    block
                    class="mt-5"
                />

              </div>

            </template>
          </ws-accordion>
        </template>

        <template #item.style>

          <!-- Horizontal Alignment -->
          <div>
            <h4 style="font-size : 14px" :style="`color : ${wsDARKER}`"> {{ $t('Align') }}</h4>
            <ws-button-group
                @input="editNewsletterConfig"
                v-model="$store.state.finemailer.newsletterConfig.footer_align"
                :items="ALIGNMENT_SELECT"
                class="mb-2 mt-2"
            />
          </div>

          <!-- Colors -->
          <ws-accordion
              :items="acordionColorItems"
              class="mt-6"
          >
            <template #item.color>

              <div class="pa-4">
                <avalon-color-picker
                    @input="editNewsletterConfig"
                    v-for="(param,j) in footerColorsVariables" :key="j + 'group'"
                    :label="param.text"
                    v-model="$store.state.finemailer.newsletterConfig.colors[param.value]"
                    :color-function="EMAIL_COLOR"
                    font-regular
                    :class="[{'mb-10' : i === (colorsParamsArray.length -1) && j=== (group.items.length -1)  }]"
                />
              </div>

            </template>
          </ws-accordion>

        </template>

      </block-settings-navigation>
    </template>

  </block-settings-navigation>

</div>
</template>

<script>
import avalonPalettePicker from "@/components/AvalonEditor/DesigSystemUI/AvalonPalettePicker";
import avalonColorPicker from "@/components/AvalonEditor/DesigSystemUI/AvalonColorPicker";
import finemailer from "@/modules/finemailer/mixins/finemailer";
import {mapActions} from "vuex";
import draggable from "vuedraggable";

import blockSettingsNavigation from "@/components/AvalonEditor/Dialogs/blocks/UI/blockSettingsNavigation";
import imageParamPicker from "@/components/AvalonEditor/UI/imageParamPicker";

export default {
  name: "emailSettingsGeneral",
  mixins : [finemailer],
  components : {
    avalonPalettePicker,
    avalonColorPicker,
    blockSettingsNavigation,
    draggable,
    imageParamPicker,
  },
  data() {
    return {
      selectedContentCategory : null,
      displayEditPalette : false,
      hidePaletteTrigger : 0,
      selectedFooterCategory : null,
      selectedSocialIndex : null,
      selectedFontParam : null,
      drag : false,
      emailBodyPaddingType : null,
      selectedFontIndex : null,

      footerColorsVariables : [
        { text : 'mailer.editor.params.footer_fonts_color' , value : 'footer_fonts_color' },
        { text : 'mailer.editor.params.footer_link_color' , value : 'footer_link_color' },
        { text: 'mailer.editor.params.socials', value: 'socials_color'},
        { text : 'mailer.editor.params.footer_bg' , value : 'footer_bg' }
      ]
    }
  },
  computed : {
    acordionColorItems() {
      return [
        { name : this.$t('Color') , value : 'color'}
      ]
    },
    fontsParamsSelect() {
      return [
        { text: this.$t('avalon.config.fonts.elements.headings'),   value: 'headings' },
        { text: this.$t('avalon.config.fonts.elements.paragraphs'), value: 'paragraphs' },
        { value: 'font_family' , hidden : true },
      ]
    },
    footerContentSelect() {
      return [
        { text: this.$t('Content'), value: 'content' },
        { text: this.$t('Style'), value: 'style' },
      ]
    },
    footerSocialsSelect() {
      return [
        { name: this.$t('Socials'), value: 'socials' },
      ]
    },
    sliderContentSelect() {
      return [
        { text: this.$t('ColorStyles'), value: 'style' },
        { text: this.$t('Fonts'), value: 'fonts' },
        { text: this.$t('EmailStyle'), value: 'email' },
        { text: this.$t('Logo'), value: 'logo' },
        { text: this.$t('ContactInformation'), value: 'footer' },
      ]
    },


    navigationSelect() {
      return [
        { name : this.$t('Color') , value : 'colors' },
        { name : this.$t('Header') , value : 'header' },
        { name : this.$t('Footer') , value : 'footer' }
      ]
    },
    colorsParamsArray() {
      return [
        { name : this.$t('Letter') ,
          items : [
            { text : 'mailer.editor.params.email_color' , value : 'background' },
            { text : 'mailer.editor.params.email_body_color' , value : 'email' },
            { text : 'mailer.editor.params.email_border_color' , value : 'block' }
          ]
        },
        {
          name: this.$t('Text'), items: [
            { text : this.$t('avalon.color_styles.h1') , value : 'h1' },
            { text : this.$t('avalon.color_styles.h2') , value : 'h2' },
            { text : this.$t('avalon.color_styles.h3') , value : 'h3' },
            { text : this.$t('avalon.color_styles.h4') , value : 'h4' },
            { text : this.$t('avalon.color_styles.h5') , value : 'h5' },
            { text : this.$t('avalon.color_styles.p') , value : 'p' },
            { text : this.$t('avalon.color_styles.h6') , value : 'h6' },
          ]
        },
        { name : this.$t('Button') ,  items: [
            {text: 'ButtonColor', value: 'button_bg'},
            {text: 'ButtonText', value: 'button_text'},
            {text: 'SecondaryButtonText', value: 'secondary_button_text'},
            {text: 'SecondaryButtonColor', value: 'secondary_button_bg'}
          ]
        },
        { name : this.$t('Other') , items : [
            { text: 'avalon.color_styles.line_color', value: 'line'},
            { text: 'mailer.editor.params.logo_color', value: 'logo_color'},
            { text: 'mailer.editor.params.logo_line_color', value: 'logo_line_color'},
            { text: 'mailer.editor.params.logo_text_color', value: 'logo_text_color'},
            { text: 'Link', value: 'link'},
            { text: 'mailer.editor.params.file_color', value: 'file_color'},
            { text : 'mailer.editor.params.footer_fonts_color' , value : 'footer_fonts_color' },
            { text : 'mailer.editor.params.footer_link_color' , value : 'footer_link_color' },
            { text: 'mailer.editor.params.socials', value: 'socials_color'},
            { text : 'mailer.editor.params.footer_bg' , value : 'footer_bg' }
          ]}
      ]
    }
  },
  methods : {
    ...mapActions('finemailer' , [
      'ADD_EDIT_DESIGN',
      'ADD_EDIT_BUSINESS_EMAIL_CONFIG'
    ]),
    changeFonts(fontPair, index) {
      this.selectedFontIndex = index
      this.$store.state.finemailer.newsletterConfig.font_family_heading = fontPair.heading
      this.$store.state.finemailer.newsletterConfig.font_family_paragraph = fontPair.paragraph
      this.editNewsletterConfig()
    },
    handleReturnAction() {
      if ( this.selectedContentCategory === 'style' && this.displayEditPalette ) {
        this.hidePaletteTrigger++
        return
      }

      if ( this.selectedContentCategory === 'footer' && !!this.selectedFooterCategory ) {
        this.selectedFooterCategory = null
        return
      }

      if ( this.selectedContentCategory === 'fonts' && !!this.selectedFontParam ) {
        this.selectedFontParam = null
        return
      }

      this.selectedContentCategory = null
    },

    handleEmailBodyPaddingTypeChange(type) {
      let size = this.PADDING_SIZES.find(el => el.name === type)

      if (size) {

        this.$store.state.finemailer.newsletterConfig.body_padding_top = size.value
        this.$store.state.finemailer.newsletterConfig.body_padding_bottom = size.value
        this.$store.state.finemailer.newsletterConfig.body_padding_left = size.value
        this.$store.state.finemailer.newsletterConfig.body_padding_right = size.value
        this.editNewsletterConfig()
      }

    },
    setSocialLink(value) {
      this.SOCIALS_SELECT.forEach(social => {
        if ( value && value.toLowerCase().includes(social.value) ) {
          this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].name = social.text;
          this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON(social.value)
        }
      })

      if ( value && value.toLowerCase().includes('t.me') ) {
        this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].name = 'Telegram';
        this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON('telegram')
      }

      this.editNewsletterConfig()
    },
    selectSocial($event) {
      this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].img = this.WESTUDY_ICON($event.toLowerCase())
      this.$store.state.finemailer.newsletterConfig.socials[this.selectedSocialIndex].name = $event;
      this.editNewsletterConfig()
    },
    deleteSocial(index) {
      this.$store.state.finemailer.newsletterConfig.socials.splice(index , 1)
      this.editNewsletterConfig()
    },
    duplicateSocial(item , index) {
      this.$store.state.finemailer.newsletterConfig.socials.splice(index , 0 , this.COPY(item))
      this.editNewsletterConfig()
    },
    addSocial() {
      if ( !this.EMAIL_CONFIG.socials ) {
        this.$store.state.finemailer.newsletterConfig.socials = []
      }
      this.$store.state.finemailer.newsletterConfig.socials.push({
        name : '',
        link : '',
        img : ''
      })
      this.$store.state.finemailer.newsletterConfig = this.COPY(this.$store.state.finemailer.newsletterConfig)

      this.editNewsletterConfig()
    },

    async editBusinessEmailConfig(value , param) {
      if ( value === this.$store.state.finemailer.businessConfig[param]) {
        return
      }
      let result = await this.ADD_EDIT_BUSINESS_EMAIL_CONFIG({[param] : value})
      if ( !result ) {
        return this.ERROR()
      }
      this.$store.state.finemailer.businessConfig[param] = value
      this.$store.state.finemailer.businessConfig = this.COPY(this.$store.state.finemailer.businessConfig)
      this.editNewsletterConfig()
    },

    editPaletteColor($event) {
      this.$store.state.finemailer.newsletterConfig.palette[$event.color] = $event.value
      this.$store.state.finemailer.newsletterConfig = this.COPY(this.$store.state.finemailer.newsletterConfig)
      this.editNewsletterConfig()
    },
    editNewsletterConfig() {
      this.$store.state.finemailer.newsletterConfig = this.COPY(this.$store.state.finemailer.newsletterConfig)
      this.$store.state.finemailer.selectedNewsletter.config = this.COPY(this.EMAIL_CONFIG)
      this.ADD_EDIT_DESIGN(this.$store.state.finemailer.selectedNewsletter)
    }
  }

}
</script>

<style scoped>

</style>