import { render, staticRenderFns } from "./AvalonPalettePicker.vue?vue&type=template&id=9445db8e&scoped=true&"
import script from "./AvalonPalettePicker.vue?vue&type=script&lang=js&"
export * from "./AvalonPalettePicker.vue?vue&type=script&lang=js&"
import style0 from "./AvalonPalettePicker.vue?vue&type=style&index=0&id=9445db8e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9445db8e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VColorPicker } from 'vuetify/lib/components/VColorPicker';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VBtn,VColorPicker,VDivider,VHover,VSheet})
