<template>
  <div v-if="!displayEdit">
    <v-sheet
        :style="`border : 1px solid ${wsDARKLIGHT} !important`"
        class="mt-3 mb-3 overflow-hidden"
        style="border-radius: 8px"
        color="transparent"   >

      <div class="d-flex">

        <v-sheet
            v-for="(color , index ) in AVALON_COLORS_LIST" :key="color"
            :class="[{roundedTopLeft : index === 0} , {roundedTopRight : index === 5}]"
            width="100%" height="100" :color="getColor(color)" />
      </div>


      <v-divider :style="`border-color : ${wsDARKLIGHT}; height : 1px`" />

      <v-btn
          @click="openEditPalette"
          block text :color="wsACCENT" class="noCaps"> {{ $t('EditPalette') }}</v-btn>
    </v-sheet>
  </div>

  <div v-else>
    <!-- Colors selector-->
    <v-sheet
        class="mt-3 mb-3"
        style="border-radius: 8px"
        color="transparent"   >
      <div class="d-flex">
        <!-- Colors -->
        <v-hover
            #default="{hover}"
            v-for="( color , index ) in AVALON_COLORS_LIST" :key="color"
        >
          <v-sheet

              @click="selectedColorName = color"
              :color="getColor( color)"
              :style="hover || (selectedColorName) === color ? `box-shadow : 0 0 0 2px ${wsDARKLIGHT}; margin-right: 2px` : null "
              :class="[
                {roundedTopLeft : index === 0} ,
                {roundedBottomLeft : index === 0} ,
                {roundedTopRight : index === 5},
                {roundedBottomRight : index === 5}
            ]"
              class="pointer"
              width="100%"
              height="32"
          >
            <span style="display: none">{{ color }}</span>
          </v-sheet>
        </v-hover>
      </div>

    </v-sheet>

    <ws-text-field
        @change="editPaletteColor"
        v-model="paletteBuffer[selectedColorName]"
        class="mt-4"
        background-color="white"
    />

    <v-color-picker
        class="mt-3"
            @input="changeSliderColor"
            :value="getColor(paletteBuffer[selectedColorName])"
        hide-inputs
        hide-mode-switch
        style="background-color: transparent"
    />



  </div>

</template>

<script>
export default {
  name: "AvalonPalettePicker",
  props : {
    palette : {
      type : Object,
      default() { return {} }
    },
    colorFunction : {
      type : Function
    },
    hidePaletteTrigger : {
      type : Number,
      default : 0
    }
  },
  data() {
    return {
      displayEdit : false,
      selectedColorName : 'background_main',
      paletteBuffer : {},
    }
  },
  watch : {
    displayEdit(value) {
      this.$emit('display-edit' , value)
    },
    hidePaletteTrigger() {
      this.displayEdit = false
    }
  },
  methods : {
    changeSliderColor(color) {
      if ( typeof color === 'object' && color !== null ) {
        this.paletteBuffer[this.selectedColorName] = '#FFFFFF'
        return
      }

      if ( this.timeOutId ) {
        clearTimeout(this.timeOutId)
      }
      this.timeOutId = setTimeout(() => {
        this.paletteBuffer[this.selectedColorName] = color
        this.paletteBuffer = this.COPY(this.paletteBuffer)
        this.editPaletteColor(color)
      },200)
    },
    editPaletteColor(value) {
      this.$emit('edit-palette' , { color : this.selectedColorName , value : value })
    },
    openEditPalette() {
      this.displayEdit = true
    },
    getColor(color) {
      if (this.colorFunction) {
        return this.colorFunction(color)
      }
      return this.GET_AVALON_COLOR(color)
    }
  },
  mounted() {
    this.paletteBuffer = this.COPY(this.palette)
  }
}
</script>

<style scoped>
.roundedTopLeft  {
  border-top-left-radius : 4px;
}
.roundedBottomLeft  {
  border-bottom-left-radius : 4px;
}
.roundedTopRight  {
  border-top-right-radius : 4px;
}
.roundedBottomRight  {
  border-bottom-right-radius : 4px;
}
</style>